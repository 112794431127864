import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GrGoogle, GrLinkedin } from 'react-icons/gr';

const TeamMember = ({member, isCore=false}) => (
  <div className={"team-member " + (isCore ? "team-member-small" : "team-member-medium")} key={member.name}>
    {
      member.photo ? (
        <img src={member.photo?.file.url}  alt={`${member.photo?.title} - profile`}/>
      ) : (
        <div className="no-profile-img-member">
          <StaticImage src="../../../images/team/no-profile-img.png" alt="No profile picture"/>
        </div>
      )
    }
    <h3>{member?.name}</h3>
    <h4>{member?.role}</h4>
    {
      member?.email !== null && (
        <a className="team-member-icon-ctn" href={`mailto:${member?.email}`}>
          <GrGoogle/>
          <p>{member?.email}</p>
        </a>
      )
    }
    {
      member?.linkedin !== null && (
        <a className="team-member-icon-ctn" href={member?.linkedin} target="_blank" rel="noreferrer">
          <GrLinkedin/>
          <p>{member?.linkedin?.substring(12)}</p>
        </a>
      )
    }
  </div>
);

export default TeamMember;
