import React from 'react';
import Layout from '../../components/layout/layout';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import Seo from '../../components/seo';
import '../team/team-style.css';
import { StaticImage } from 'gatsby-plugin-image';
import {GrGoogle, GrLinkedin} from 'react-icons/gr';
import TeamMember from './components/team-member';
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import getCurrentTranslations from '../../components/contentful-translator';


const TeamPage = ({data}) => {

  const {t} = useTranslation();
  const [management, setManagement] = React.useState([])
  const [directors, setDirectors] = React.useState([])
  const [members, setMembers] = React.useState([])

  const {language} = React.useContext(I18nextContext);

  React.useEffect(() => {

    const properData = getCurrentTranslations(data.teamMembers.edges, language)
    let teams = [];
    for (let member of properData) {

      let isDepartmentAlreadyIn = false;
      for (let team of teams) {
        if (member.node.department === team.department){
          team.members.unshift(member.node);
          isDepartmentAlreadyIn = true;
        }
      }
      if (isDepartmentAlreadyIn === false)
        teams.unshift({
          department: member.node.department,
          members: [member.node]
        })
    }

    const MANAGEMENT_NAME = 'Zarząd';
    const DIRECTORS_NAME = 'Dyrektorzy';
    const CORE_TEAM = 'Core team';

    setManagement(teams.filter(team => team.department === MANAGEMENT_NAME)[0]);
    setDirectors(teams.filter(team => team.department === DIRECTORS_NAME)[0]);
    const members = teams
      .filter(team => team.department === CORE_TEAM)
      .map(team => team.members)
      .flat()
      .sort((t1, t2) => t1.name.localeCompare(t2.name))
    setMembers(members)
  }, [data, language])

  return (
    <Layout withHeader={false}>
      <Seo
        title={t('team.page-title')}
        description={t('team.page-description')}
        ogImage={ogImage}
      />

      <StaticImage
        className="theme-element theme-element1"
        src="../../images/team/theme1.png"
        alt="Theme circles"
        placeholder="none"
        loading="lazy"
      />
      <StaticImage
        className="theme-element theme-element2"
        src="../../images/team/theme2.png"
        alt="Theme circles"
        placeholder="none"
        loading="lazy"
      />
      <StaticImage
        className="theme-element theme-element3"
        src="../../images/team/theme3.png"
        alt="Theme circles"
        placeholder="none"
        loading="lazy"
      />
      <StaticImage
        className="theme-element theme-element4"
        src="../../images/team/theme4.png"
        alt="Theme circles"
        placeholder="none"
        loading="lazy"
      />
      <StaticImage
        className="theme-element theme-element5"
        src="../../images/team/theme5.png"
        alt="Theme circles"
        placeholder="none"
        loading="lazy"
      />

      <section className="page-section general-margin-team">
        <div className="team-header-ctn">
          <h1>{t`team-title-1`}</h1>
          <h1>{t`team-title-2`}</h1>
        </div>

        <div className="team-members-background">
          <h2>{t`team-management`}</h2>
          <div className="container team-members-grid">
            {
              management.members?.map(member => (
                <div className="team-member" key={member.name}>
                  <img src={member.photo?.file.url}  alt={`${member.photo?.title} - profile`}/>
                  <div className="management-member-text-ctn">
                    <h3>{member.name}</h3>
                    <h4>{member.role}</h4>
                    <p>{member.description?.description}</p>
                    <a className="team-member-icon-ctn" href={`mailto:${member?.email}`}>
                      <GrGoogle/>
                      <p>{member?.email}</p>
                    </a>
                    <a className="team-member-icon-ctn" href={member?.linkedin} target="_blank" rel="noreferrer">
                      <GrLinkedin/>
                      <p>{member?.linkedin.substring(12)}</p>
                    </a>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="team-members-background">
          <h2>{t`team-board`}</h2>
          <div className="container team-members-grid">
            {
              directors.members?.map(member => (<TeamMember member={member}/>))
            }
          </div>
        </div>
      </section>

      <section className="team-mid-section-ctn">
        <div>
          <h2>{t`team-whole-team`}</h2>
        </div>
        <StaticImage src="../../images/team/inhub-team.jpeg" alt="InHub team"/>
      </section>

      <section style={{position: 'relative'}}>
        <StaticImage
          className="theme-element theme-element6"
          src="../../images/team/theme6.png"
          alt="Theme circles"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="theme-element theme-element7"
          src="../../images/team/theme7.png"
          alt="Theme circles"
          placeholder="none"
          loading="lazy"
        />
        <div className="team-members-background">
          <h2>{t`team-rest-team`}</h2>
          <div className="container team-heart-ctn team-members-grid">
            {
              members.map(member => <TeamMember member={member} isCore={true}/>)
            }
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default TeamPage;
